<template>
  <div class="statistics-box">
    <div class="statis-header d-flex align-center justify-around">
      <!-- <div class="date d-flex justify-center align-center">
        <van-button type="default" size="small" @click="show = true">选择日期</van-button>
      </div> -->
      <!-- <div class="search flex-1">
        <van-search v-model="value" shape="round" placeholder="请输入搜索关键词" />
      </div> -->
    </div>
    <div class="statis-body">
      <router-view v-slot="{ Component }">
        <transition name="no-mode-fade">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
    <div class="tabbar">
      <van-tabbar v-model="active" @change="onChange">
        <van-tabbar-item to="/statis/staff-statis" replace
          >员工统计</van-tabbar-item
        >
        <van-tabbar-item to="/statis/data-statis" replace
          >楼层统计</van-tabbar-item
        >
      </van-tabbar>
    </div>
    <div class="go-home">
      <van-button
        round
        plain
        hairline
        type="primary"
        size="large"
        @click="goHome()"
      >
        <van-icon name="wap-home-o" />
      </van-button>
    </div>
  </div>
</template>

<script>
import { ref, watch, onUnmounted } from "vue";
import { Tabbar, TabbarItem, Search, Button, Calendar, Icon } from "vant";
import { useRoute, useRouter } from "vue-router";
export default {
  name: "Statis",
  components: {
    "van-tabbar": Tabbar,
    "van-tabbar-item": TabbarItem,
    "van-search": Search,
    "van-button": Button,
    "van-calendar": Calendar,
    "van-icon": Icon,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    //   tabbar 栏
    const active = ref(0);
    const at = localStorage.getItem("active3");
    if (!at) {
      active.value = 0;
    } else {
      active.value = Number(at);
    }
    // 回首页
    const goHome = () => {
      router.push({ path: "/", replace: true });
    };
    const onChange = (index) => {
      // console.log(index);
    };
    // 日期选择
    const date = ref("");
    const show = ref(false);
    const formatDate = (date) => `${date.getMonth() + 1}/${date.getDate()}`;
    const onConfirm = (value) => {
      // console.log(value);
      show.value = false;
      date.value = formatDate(value);
    };

    // 搜索框的值
    const value = ref("");
    watch(
      () => route.path,
      () => {
        if (route.path == "/statis/data-statis") {
          active.value = 1;
        } else {
          // console.log("staff-statis");
          active.value = 0;
        }
        localStorage.setItem("active3", active.value);
      }
    );
    // 组件销毁
    onUnmounted(() => {
      localStorage.setItem("active3", 0);
    });
    return {
      // protyper
      active,
      show,
      date,
      value,
      // minDate: new Date(2020, 0, 1),
      // maxDate: new Date(2025, 10, 1),
      route,
      // method
      onChange,
      onConfirm,
      goHome,
    };
  },
};
</script>
<style scoped lang="less">
.tabbar {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100px;
  z-index: 999;
}
.date {
  width: 132px;
}
.statis-header {
  // border-bottom: 2px solid #ccc;
  padding: 0 20px;
  // margin-bottom: 40px;
}
.statis-body-header {
  margin-bottom: 40px;
}
.building-layer-box {
  border-bottom: 2px solid #ccc;
  margin-bottom: 40px;
  .item {
    border-top: 2px solid #ccc;
    padding: 20px;
    font-size: 32px;
  }
}
.span-title {
  text-align: left;
  width: 80px;
}
.go-home {
  position: fixed;
  width: 104px;
  bottom: 100px;
  left: 20px;
  border-radius: 50%;
  background-color: white;
  z-index: 999;
  box-shadow: 1px 1px 10px lightblue;
}
.no-mode-fade-enter-active,
.no-mode-fade-leave-active {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s;
}

.no-mode-fade-enter-from,
.no-mode-fade-leave-to {
  opacity: 0;
}

::v-deep .van-field__control,
::v-deep .van-tabbar-item,
::v-deep .van-cell {
  font-size: 30px;
}
</style>
